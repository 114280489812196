import * as React from "react";
import PropTypes from "prop-types";
import { useAutocomplete } from "@mui/base/useAutocomplete";
import { Button } from "@mui/base/Button";
import { Popper } from "@mui/base/Popper";
import { styled } from "@mui/system";
import { unstable_useForkRef as useForkRef } from "@mui/utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import PerfectScrollbar from "react-perfect-scrollbar";
import { CustomPopper } from "./CustomPopper";
import CustomInput from "./CustomInput";

const RootAutoComplete = React.forwardRef((props, ref) => {
  const {
    getOptionLabel,
    placeholder,
    label,
    disabled,
    variant,
    error,
    helperText
  } = props
  return <CustomDropdown 
    PopperComponent={CustomPopper}
    getOptionLabel={(option) => {
      return option?.name ? option.name : typeof(option) != 'object'? option : '';
    }}
    renderInput={(params) => (
      <TextField
        {...params} label={label} placeholder={placeholder} variant={variant} error={error} helperText={helperText}
      />
    )}
    onChange={(e, newValue) => {
      console.log(newValue)
      onChange({ target: { name: name, value: newValue } });
    }}
    {...props} 
    ref={ref} 
  />;
});

export default function CustomAutoComplete(props) {
  return <RootAutoComplete 
    {...props}
  />;
}

const CustomDropdown = styled(Autocomplete)(({ theme, disabled }) => ({
    width: "100%",
    padding: "10px 0 10px 0",
    "& .MuiOutlinedInput-root": {
      '& .MuiAutocomplete-input':{
        padding:'3px',
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.inputBorderColor,
        color: theme.palette.text.disabled,
      },
      "&:hover fieldset": {
        border: disabled ? '' :'2px solid '+ theme.palette.primary.main,
      },
      "&.Mui-focused fieldset": {
        border: '2px solid '+ theme.palette.primary.main,
      },
    },
  }));