// @mui material components

//  React components
import MDBox from "components/MDBox";

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "examples/Tables/DataTable";

// @mui material components

// Images

// Data
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//  React components


// Billing page components
import { useTheme } from "@emotion/react";
import { caseAPI } from "api";
import ClaimNavbar from "examples/Navbars/ClaimNavbar";
import { useStyles } from "examples/Navbars/ClaimNavbar/styles";
import CustomerData from "./customerData";
import NameChange from "./nameChange";

function WorkflowUpdate() {
  const navigation = useNavigate();
  const [assignAgents, setAssignAgents] = useState([]);
  const [state, setState] = useState({});
  const [workflows, setWorkflows] = useState([]);
  const [workflowDetail, setWorkflowDetail] = useState({});
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [isLoadingWorflowDetails, setIsLoadingWorflowDetails] = useState(false);
  const [showComponent, setShowComponent] = useState({
    autoAssign: false,
    customerAssign: false,
  });
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [showNameChange, setShowNameChange] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});

  const handleNameChange = (customer_code, key, value) => {
    setShowNameChange(true);
    setCustomerDetails({ customer_code, key, value });
  };

  const {
    columns: pColumns,
    rows: pRows,
    callbackFunction: callbackFunction,
  } = CustomerData({
    setIsLoading: setIsLoadingTable,
    agents: assignAgents,
    workflows: workflows,
    handleNameChange: handleNameChange,
  });

  const updateAutoTransfer = (stepName, agentEmail) => {
    setIsLoadingWorflowDetails(true);
    caseAPI
      .updateWorflowAutoTransfer(state.workflow, stepName, agentEmail)
      .then((res) => {
        if (res.status == 200) {
          setWorkflowDetail({
            ...workflowDetail,
            [stepName]: {
              ...workflowDetail[stepName],
              auto_transfer_email: agentEmail,
            },
          });
          setIsLoadingWorflowDetails(false);
        }
      })
      .catch((error) => {
        setIsLoadingWorflowDetails(false);
      });
  };

  const updateInitAgent = (agentEmail) => {
    setIsLoadingWorflowDetails(true);
    caseAPI
      .updateWorflowInitAgent(state.workflow, agentEmail)
      .then((res) => {
        if (res.status == 200) {
          setState({ ...state, init_agent: agentEmail });
          setIsLoadingWorflowDetails(false);
        }
      })
      .catch((error) => {
        setIsLoadingWorflowDetails(false);
      });
  };

  const getWorkflows = () => {
    caseAPI
      .getWorkflows()
      .then((res) => {
        if (res.status == 200) {
          setWorkflows(res.data);
        }
      })
      .catch((error) => {});
  };

  const getWorkflowDetails = (workflowId) => {
    setIsLoadingWorflowDetails(true);
    caseAPI
      .getWorkflowById(workflowId)
      .then((res) => {
        if (res.status == 200) {
          setWorkflowDetail(res.data.workflow);
          setState((prevState) => {
            return {
              ...prevState,
              workflow: workflowId,
              init_agent: res.data.init_agent,
            };
          });
          setIsLoadingWorflowDetails(false);
        }
      })
      .catch((error) => {
        setIsLoadingWorflowDetails(false);
      });
  };

  const getAgents = () => {
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          setAssignAgents(res.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getWorkflows();
    getAgents();
  }, []);

  const handleWorkflowSelect = (id) => {
    // let init_agent = workflows.filter((workflow) => {
    //   return workflow._id == e.target.value;
    // })[0]?.init_agent;
    // setState({ ...state, workflow: e.target.value, init_agent: init_agent });
    getWorkflowDetails(id);
  };

  const selectedStyle = {
    backgroundColor: "rgb(56,143,237)",
    color: "white !important",
  };
  const styles = useStyles();
  const { palette, functions, typography } = useTheme();
  const { primary, white, secondary, warning, text } = palette;
  const { pxToRem } = functions;

  return (
    <DashboardLayout>
      <ClaimNavbar
        icon={
          <MDBox sx={styles.iconHolder}>
            <span
              style={{ fontSize: "1.3em" }}
              class="material-symbols-outlined"
            >
              rebase_edit
            </span>
          </MDBox>
        }
        leftContent={
          <MDBox sx={{ fontSize: "16px", fontWeight: "500" }}>
            Workflow Update
          </MDBox>
        }
      />

      <MDBox
        sx={{
          display: "flex",
          fontSize: typography.size["sm"],
          fontWeight: typography.fontWeightMedium,
          gap: "1em",
        }}
        mt={2}
      >
        <MDBox
          sx={{
            padding: pxToRem(8),
            cursor: "pointer",
            gap: "5px",
            display: "flex",
            paddingBottom: pxToRem(5),
          }}
          color={palette.text.header}
        >
          Customer Wise User Update
        </MDBox>
      </MDBox>

      <MDBox sx={{ marginTop: "16px" }}>
        <DataTable
          table={{ columns: pColumns, rows: pRows }}
          isSorted={true}
          entriesPerPage={true}
          showTotalEntries={true}
          downloadable={false}
          noEndBorder
          canSearch={true}
          isLoading={isLoadingTable}
          // columnFilter={true}
          tableHeight={"calc(var(--h-100vh) - 235px)"}
        />
      </MDBox>

      {showNameChange && Object.keys(customerDetails).length != 0 && (
        <NameChange
          open={showNameChange}
          handleClose={setShowNameChange}
          customerDetails={customerDetails}
          callbackFunction={callbackFunction}
        ></NameChange>
      )}
    </DashboardLayout>
  );
}

export default WorkflowUpdate;
