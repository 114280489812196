import { useTheme } from "@emotion/react";
import { AIAgentAPI, caseAPI } from "api";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import SummarySkeleton from "skeletons/forms/summarySkeleton";
import ContentBox from "../../rightPanel/components/general/ContentBox";
import { useGlobalState } from "Global";
import { setGlobalState } from "Global";
import { sparkleSvg } from "examples/svgIcons/svgIcons";
import { useParams } from "react-router-dom";
import FeatureUpdateAction from "./actions/featureUpdateAction";
import FileUpdateAction from "./actions/fileUpdateAction";
import AutoTransferAction from "./actions/autoTransferAction";
import StepChangeAction from "./actions/stepChangeAction";

import { format } from "date-fns";
import { toFloatMark } from "utils/conversion";
import AiLoading from "../components/AiLoading";

const AISuggestedActions = ({ caseId, close }) => {
  const { palette, typography } = useTheme();
  const { primary, secondary, white } = palette;

  const [isLoading, setIsLoading] = useState(true);

  const [featureAction, setFeatureAction] = useState(null);
  const [fileAction, setFileAction] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [transferAction, setTransferAction] = useState(null);
  const [stepChange, setStepChange] = useState(null);
  const [noStepChange, setNoStepChange] = useState(null);
  const [loaders, setLoaders] = useState({
    file_action: false,
    feature_action: false,
    step_change: false,
    autotransfer: false,
    no_step_change: false,
    suggestion: false,
  });
  const [userDetails] = useGlobalState("userDetails");
  const updateMapping = {
    suggestion: setSuggestions,
    feature_action: setFeatureAction,
    file_action: setFileAction,
    transfer_action: setTransferAction,
  };

  const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } =
    AIAgentAPI.getCaseSuggestionsWS({
      case_id: caseId,
      token: `Bearer ${userDetails["access_token"]}`,
    });

  const sendCustomMessage = (message) => {
    if (readyState == 1) {
      sendJsonMessage(message);
    }
  };

  const getSuggestions = async () => {
    setLoaders((prevLoaders) => {
      Object.keys(prevLoaders).map((loader) => {
        prevLoaders[loader] = true;
      });
      return { ...prevLoaders };
    });
    sendCustomMessage({ tasks: ["suggestion"] });
    sendCustomMessage({
      tasks: ["feature_action", "file_action"],
      type: "info",
    });
  };

  useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage["status_code"] == 200) {
        if (lastJsonMessage["type"] && lastJsonMessage["type"] == "action") {
          if (lastJsonMessage["name"] == "autotransfer") {
            sendCustomMessage({ tasks: ["all"] });
          } else {
            sendCustomMessage({ tasks: [lastJsonMessage["name"]] });
          }
        }
        let response = lastJsonMessage["content"];

        if (typeof response == typeof {}) {
          if ("suggestion" in response) {
            setSuggestions(response["suggestion"]);
            setIsLoading(false);
          } else if ("feature_action" in response) {
            setFeatureAction(response["feature_action"]);
          } else if ("file_action" in response) {
            setFileAction(response["file_action"]);
          } else if ("autotransfer" in response) {
            setTransferAction(response["autotransfer"]);
            setNoStepChange((prev) => null);
            setStepChange((prev) => null);
          } else if ("step_change" in response) {
            setStepChange(response["step_change"]);
            setNoStepChange((prev) => null);
            setTransferAction((prev) => null);
          } else if ("no_step_change" in response) {
            setNoStepChange(response["no_step_change"]);
            setTransferAction((prev) => null);
            setStepChange((prev) => null);
          }
          setLoaders((prevLoaders) => {
            return { ...prevLoaders, [Object.keys(response)[0]]: false };
          });
        }
      } else if (lastJsonMessage["status_code"] != 200) {
        if (lastJsonMessage["type"] && lastJsonMessage["type"] == "action") {
          setLoaders((prevLoaders) => {
            return { ...prevLoaders, [lastJsonMessage["name"]]: false };
          });
        }
        setGlobalState("error", {
          open: true,
          message: lastJsonMessage["content"],
          type: "error",
        });
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    getSuggestions();
  }, [readyState]);

  const params = useParams();
  const [agents, setAgents] = useState([]);
  const handleChange = (e, actionType, type = "Text", index) => {
    let { name, value } = e.target;

    if (type == "Date") {
      value = format(value, "yyyy-MM-dd");
    } else if (type == "Integer") {
      if (value) {
        let newVal = toFloatMark(value);
        if (value.slice(-1) == ".") {
          value = newVal + ".";
        } else if (value.slice(-2) == ".0") {
          value = newVal + ".0";
        } else {
          value = newVal;
        }
      }
    }

    // if (e.target.value?.email_id) {
    //   value = e.target.value.email_id;
    // }

    let dynamicAction = updateMapping[actionType];

    if (actionType === "feature_action") {
      setFeatureAction((pre) =>
        pre.map((data, i) => {
          if (i == index) {
            return { ...data, value: value };
          }
          return data;
        })
      );
    } else {
      dynamicAction((prevAction) => {
        return { ...prevAction, value: value };
      });
    }
  };

  const handlePerformAction = (action, data = {}) => {
    if (action.name == "file_action") {
      setLoaders((prevLoaders) => {
        return { ...prevLoaders, file_action: true };
      });
      AIAgentAPI.performCaseAction(
        { case_id: params.case_id, data: JSON.stringify(action) },
        data
      )
        .then((res) => {
          if (res.status == 200) {
            sendCustomMessage({ tasks: ["file_action", "suggestion"] });
          }
        })
        .catch((error) => {
          setLoaders((prevLoaders) => {
            return { ...prevLoaders, file_action: false };
          });
        });
    } else {
      sendCustomMessage({
        name: action.name || action[0].name,
        value: action,
        type: "action",
      });
      setLoaders((prevLoaders) => {
        return { ...prevLoaders, [action.name || action[0].name]: true };
      });
    }
  };

  const getAgents = () => {
    caseAPI
      .getAgents()
      .then((res) => {
        if (res.status == 200) {
          setAgents(res.data);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getAgents();
  }, []);
  return (
    <MDBox
      sx={{
        height: "100%",
        backgroundColor: white.main,
        borderRadius: "10px",
        boxShadow: "0px 0px 8px 1px #0000000D",
      }}
    >
      <MDBox
        sx={{
          background:
            "linear-gradient(90.05deg, #666CFF 9.14%, #00CC9B 88.27%)",
          borderRadius: "10px",
          height: "8px",
          opacity: ".4",
        }}
      ></MDBox>
      <MDBox
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1em",
        }}
      >
        <MDBox
          sx={{
            position: "relative",
            display: "inline-block",
            fontSize: "16px",
            fontWeight: typography.fontWeightBold,
          }}
          color={secondary.main}
        >
          Suggested Actions
          <MDBox
            sx={{
              position: "absolute",
              top: "0",
              right: "-25px",
            }}
          >
            {sparkleSvg(secondary.main)}
          </MDBox>
        </MDBox>

        <MDBox
          sx={{ cursor: "pointer" }}
          color={primary.main}
          onClick={close}
          mx={0.5}
        >
          x Close
        </MDBox>
      </MDBox>
      <ContentBox height={"calc(100% - 80px)"} sx={{ padding: "0 1.5em" }}>
        {isLoading === true ? (
          <AiLoading
            messages={[
              "Gathering key insights...",
              "Analyzing patterns and data...",
              "Crafting your next best steps...",
            ]}
            time={900}
          />
        ) : (
          suggestions?.map((data, index) => (
            <MDBox
              sx={{
                display: "flex",
              }}
              key={index}
            >
              <MDBox
                sx={{
                  marginRight: "15px",
                  position: "relative",
                }}
              >
                <MDBox
                  sx={{
                    height: "30px",
                    width: "30px",
                    fontSize: typography.size["xs"],
                    fontWeight: "bold",
                    background: "#E6FFF9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "100%",
                    position: "relative",
                    marginTop: "10px",
                    zIndex: "1",
                  }}
                  color={secondary.main}
                >
                  {index + 1}
                </MDBox>
                <MDBox
                  sx={{
                    height: "100%",
                    borderLeft: `1px solid ${palette.text.light}`,
                    zIndex: "0",
                    position: "absolute",
                    top: "0",
                    left: "50%",
                  }}
                ></MDBox>
              </MDBox>
              <MDBox sx={{ margin: "10px 15px 10px 0", width: "100%" }}>
                <MDBox
                  sx={{
                    boxShadow: "0px 0px 8px 1px #00000014",
                    padding: "10px",
                    borderRadius: "8px",
                    backgroundColor: primary.light,
                    width: "100%",
                  }}
                >
                  {data}
                </MDBox>
                <MDBox>
                  {index === 0 ? (
                    <FeatureUpdateAction
                      featureAction={featureAction}
                      handleChange={handleChange}
                      handlePerformAction={handlePerformAction}
                      loaders={loaders}
                    />
                  ) : index === 1 ? (
                    <FileUpdateAction
                      action={fileAction}
                      handlePerformAction={handlePerformAction}
                      loaders={loaders}
                    />
                  ) : index === 2 ? (
                    <AutoTransferAction
                      agents={agents}
                      action={transferAction}
                      handleNotificationChange={handleChange}
                      handlePerformAction={handlePerformAction}
                      loaders={loaders}
                    />
                  ) : index === 3 ? (
                    <StepChangeAction
                      agents={agents}
                      action={stepChange}
                      handleNotificationChange={handleChange}
                      handlePerformAction={handlePerformAction}
                      loaders={loaders}
                    />
                  ) : (
                    <></>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
          ))
        )}

        {noStepChange && !(fileAction || featureAction) && (
          <MDBox
            sx={{
              fontSize: "0.74rem !important",
              paddingTop: "0.5rem",
              color: "tomato",
            }}
            className={"font-unset"}
          >
            * {noStepChange.reason_for_decision}
          </MDBox>
        )}
      </ContentBox>
    </MDBox>
  );
};

export default AISuggestedActions;
